import './ErrorMessage.scss';
import {Link} from 'react-router-dom';

const ErrorMessage = ({errorMessage, returnUrl, returnMessage}) => {

    return (
        <section className="error-message">
            <h2 className="error-message__title">Sorry, we encountered an error</h2>
            { (errorMessage && errorMessage.length > 0) ? <p className="error-message__copy">{errorMessage}</p>: ""}
            <p className="error-message__copy">{ (returnUrl && returnMessage && returnUrl.length > 0 && returnMessage.length > 0) ? <Link to={returnUrl+window.location.search}>{returnMessage}</Link> : <Link to={`/${window.location.search}`}>Return to homepage</Link> }</p>
        </section>
    )
}

export default ErrorMessage;