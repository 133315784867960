import { useEffect, useState } from 'react';
import YouTube from 'react-youtube';
import VideoStatic from '../../assets/images/trailer/video_static.webp';
import VideoPlayButton from '../../assets/images/trailer/play_button.svg';
import './Trailer.scss';

export default function Trailer() {

    const [showTrailer, setShowTrailer] = useState(false);

    useEffect(() => {
        setShowTrailer(false);
    }, [])

    function handleClickPlay() {
        setShowTrailer(!showTrailer);
    }

    function handleVideoPlay() {
        // setPauseAnim(true);
    }
    function handleVideoPause() {
        // setPauseAnim(false);
    }

    // settings for YT player
    const opts = {
        height: '450',
        width: '800',
        host: 'https://www.youtube-nocookie.com',
        'data-ot-ignore': 'true',
        playerVars: {
            autoplay: 1,
            rel: 0,
            enablejsapi: 1,
        },
    };
    
    return (
        <section className="trailer">
            {/* <div className="trailer__copy-box"> */}
                <h2 className="trailer__title">Join the ultimate digital playground!</h2>
                <div className="trailer__copy">You will fly, race, zoom, crash, compete and more on an epic 3D island built by you and the Cubric community.</div>
                <h2 className="trailer__subtitle">Discover like never before.</h2>
            {/* </div> */}
            <div className="trailer__video-box">

                {(!showTrailer) ? 
                    <>
                    <img className="trailer__video-play-button" src={VideoPlayButton} loading="lazy" tabIndex={"0"} role="button" aria-label="click to play the Cubric video trailer" alt="Click to play the Cubric trailer video"  onClick={handleClickPlay} />
                    <img className="trailer__video-placeholder" src={VideoStatic} loading="lazy" tabIndex="0" aria-label="click to play the Cubric video trailer" alt="The Cubric Game Trailer" width="853" height="480" onClick={handleClickPlay} /> 
                    </>
            : <>
                { (window.OnetrustActiveGroups.includes("C0004")) ? 
                    <YouTube {...`data-ot-ignore`} dataOtIgnore="true" videoId="HlzFVT1pI_s" opts={opts} iframeClassName="trailer__player" title="YouTube video player" onPlay={handleVideoPlay} onPause={handleVideoPause} onEnd={handleVideoPause} />
                : <div className="trailer__notification">
                    <iframe className="trailer__player" src="https://player.vimeo.com/video/985915431?badge=0&amp;autoplay=true&amp;dnt=true&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" title="Cubric Mobile Game Trailer"></iframe>
                    {/* <p><strong>Please enable cookies to watch&nbsp;the&nbsp;video</strong></p>
                    <button className="download-button trailer__cookie-button" onClick={()=> window.OneTrust.ToggleInfoDisplay()}>Update Cookie Settings</button>
                    <p>Click the button, allow all cookies, &amp; <strong>reload the page</strong></p> */}
                   </div> }
               </>
                }   

            </div>
        </section>
    )
}