
import './NewsCarousel.scss';

import NewsListItem from './NewsListItem';


export default function NewsCarousel({resultsData, androidUrl, appleUrl}) {

    /*
    <Link key={index} className="news-list__item-link" to={`/news/post/${result.id}${window.location.search}`}>
        <li className="news-list__item">
            <img className="news-list__item-thumb" src={result.acf.splashimageurl} alt="article thumbnail" loading="lazy" />
            {(result.categories)
            ? <p className="news-list__item-category"><CategoryName catId={result.categories[0]} /></p>
            : null}
            <h3 className="news-list__item-title" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(result.title.rendered)}}></h3>
            <p className="news-list__item-date"><GetDate theDate={result.acf.displaydate} /></p>
        </li>
    </Link>
    */



    return (

            <div className="news-carousel__list-container">
                {(resultsData) 
                ? (resultsData.map((result,index) => (<NewsListItem key={index} result={result} />)))
                : "" }
            </div>

    )
}