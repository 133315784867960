import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import CategoryName from '../CategoryName/CategoryName';
import './NewsListItem.scss';

export default function NewsListItem({result}) {

    return (
        <Link className="news-item__link" to={`/news/post/${result.id}${window.location.search}`}>
            <img className="news-item__thumb" src={`${result.acf.splashimageurl}`} alt="article thumbnail" loading="lazy" />
            <div className="news-item__copy-box">
                <p className={`news-item__category news-item__category-${result.categories[0]} category-color-${result.categories[0]}`}>
                    <CategoryName catId={result.categories[0]} />
                </p>
                <h3 className="news-item__title" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(result.title.rendered)}}></h3>
            </div>
        </Link>
    )
}