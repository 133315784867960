import './Footer.scss';

import {Link} from 'react-router-dom';
import originatorLogo from "../../assets/images/logo/originator-white.webp";
import spinMasterLogo from "../../assets/images/logo/Spin_Master_logo.gif";


export default function Footer({pageType}) {

    return (
    <footer className="footer">
        <div className="footer__logos-legal-box">
            
            <div className="footer__logos">
                <a href="https://www.originatorkids.com/" target="_blank" aria-label="Link to Originator (Opens in new tab)" rel="noreferrer"><img loading="lazy" className="footer__logos-image footer__logos-image--originator" src={originatorLogo} alt="Originator logo"/></a>
                <a href="https://www.spinmaster.com/" target="_blank" aria-label="Link to Spinmaster (Opens in new tab)" rel="noreferrer"><img loading="lazy" className="footer__logos-image" src={spinMasterLogo} alt="Spin Master logo"/></a>
            </div>
            <div className="footer__links">
                {
                    (window.location.pathname.includes("faq")) 
                    ? <button className="footer__links-item footer__links-item-button" onClick={() => window.scrollTo(0,0)}>FAQ</button>
                    : <Link to={`/faq/${window.location.search}`} className="footer__links-item">FAQ</Link>
                }
                <div className="footer__links-divider">&bull;</div>
                <a href="/privacypolicy" aria-label="Link to Privacy Policy (Opens in new tab)" target="_blank" rel="noreferrer" className="footer__links-item">Privacy Policy</a>
                <div className="footer__links-divider">&bull;</div>
                <a href="https://www.spinmaster.com/en-US/legal/terms-of-use/" aria-label="Link to Terms of Use (Opens in new tab)" target="_blank" rel="noreferrer" className="footer__links-item">Terms of Use</a>
                <div className="footer__links-divider">&bull;</div>
                <a href="mailto:support@cubric.com" target="_blank" aria-label="Link to email support (Opens in new tab)" rel="noreferrer" className="footer__links-item">Customer Service</a>
            </div>
            <p className="footer__legal">Cubric - &trade; & &copy; Spin Master Ltd. All rights reserved.</p>
            {/* <a className="footer__kidsafe" href="https://www.kidsafeseal.com/certifiedproducts/pawpatrolacademy_app.html" aria-label="Link to KidSAFE (Opens in new tab)" target="_blank" rel="noreferrer">
                <img border="0" loading="lazy" alt="Cubric is certified by the kidSAFE Seal Program." src="https://www.kidsafeseal.com/sealimage/33583706916014976/pawpatrolacademy_app_medium_darktm.png" />
            </a> */}
        </div>
    </footer>
    )
}