import './ButtonSection.scss';

export default function ButtonSection({buttonText, handleDownloadClick, appleUrl, androidUrl, buttonLocation="not set"}){

    return (
        <section className="button-section">
            <button className="download-button button-section__button" onClick={() => handleDownloadClick(buttonLocation, appleUrl, androidUrl)}>{(buttonText && buttonText.length > 0) ? buttonText : "Download Now"}</button>
        </section>
    )
}
