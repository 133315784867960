import { useEffect } from 'react';
import './FAQ.scss';
import MetaSEO from '../MetaSEO/MetaSEO';
import Accordion from "./Accordion/index";
import NavBar from '../NavBar/NavBar';
import Footer from '../Footer/Footer';
import ButtonSection from '../ButtonSection/ButtonSection';
import SocialBox from '../SocialBox/SocialBox';

export default function FAQ({handleDownloadClick, androidUrl, appleUrl}) {
    useEffect(() => {
        window.scrollTo(0,0);
    }, [])
  return (
    <>
      <MetaSEO
        title="FAQ Frequently Asked Questions | | Cubric Mobile Game | 3D World Builder with Multiplayer App | iOS"
        canonical="https://www.cubric.com/faq"
        description="FAQ Frequently Asked Questions about the Cubric Mobile Game, a 3D World Builder with Multiplayer App"
        keywords="FAQ, help, questions, platforms, compatibility, answers, guides, Cubric,games,game,app,mobile,players,sandbox,3D,avatar,craft,puzzles,roblox,build,world,blocks,multiplayer,friends,coding,minecraft,pkxd,minigames,world-builder,new,obby,island,characters,blueprints,free,ftp,creative"
        type="webapp"
        name="Originator Kids | Spin Master"
        isFAQ={true} 
        />
        <NavBar androidUrl={androidUrl} applUrl={appleUrl} handleDownloadClick={handleDownloadClick} />
        <div className="faq__banner"></div>
        <section className="faq" id="main-content">
            <h1 className="faq__title">Frequently Asked Questions</h1>

            <div className="faq__question-wrapper">
                <h2 className="accordion__section-title">Getting Started</h2>
                <Accordion
                className="accordion"
                openClassName="accordion__item--open"
                >
                <div data-header="What is Cubric?" className="accordion__item">
                    <p className="accordion__answer">
                    Cubric is the ultimate 3D world building app that celebrates creators, connection and competition. Every player's experience is unique by design, so Cubric is truly what our fans make it.
                    <br /><br />
                    Players can create their own reality with custom builds and obstacle courses and then share these designs with fellow players. While blueprints make the creation process easy, players can bypass building and explore Cubric Island's many attractions and mini games instead. Trust us when we say that Cubric is the world you've been waiting for.
                    </p>
                </div>
                
                <div
                    data-header="When and where is the app available to download?"
                    className="accordion__item"
                >
                    <p className="accordion__answer">Cubric is now available globally to players with iOS devices. Please <a href="https://cubric.onelink.me/PZuq/43j2ygtl">check the App Store</a> for device compatibility information. 
                        <br /><br />
                        Please subscribe to our social media channels for news and updates, including the latest info on our Android release dates. </p>
                </div>
                <div
                    data-header="How do I download it?"
                    className="accordion__item"
                >
                    <p className="accordion__answer">Visit the App Store to confirm eligibility and device compatibility.</p>
                </div>
                <div
                    data-header="Is the app free to download?"
                    className="accordion__item"
                >
                    <p className="accordion__answer">The app is free to download on iOS globally, though certain features will require in-app purchases.</p>
                </div>
                
                <div
                    data-header="What does beta testing mean?  Does that mean the app is not safe to download?"
                    className="accordion__item"
                >
                    <p className="accordion__answer">As part of our commitment to delivering the best possible gaming experience, we are currently in a soft launch phase or beta testing mode. While the game is accessible to the general public, we are actively gathering feedback, fine-tuning gameplay mechanics, and making optimizations to improve the experience for all players. We appreciate your patience.
                    </p>
                </div>
                </Accordion>

            
                <h2 className="accordion__section-title">Account System &amp; In-App Purchases</h2>
                <Accordion
                className="accordion"
                openClassName="accordion__item--open"
                >
                <div data-header="What is the Cubric Account System?" className="accordion__item">
                    <p className="accordion__answer">
                    The Cubric Account System, which allows the company to identify player by email, ensures customer service issues are resolved quickly and accurately. 
                    </p>
                </div>
                <div
                    data-header="How do I change my password?"
                    className="accordion__item"
                >
                    <p className="accordion__answer">You can change your password in settings.</p>
                </div>
                <div
                    data-header="How do I make in-app purchases?"
                    className="accordion__item"
                >
                    <p className="accordion__answer">Coins can be purchased in the coin store which can be accessed by tapping this during gameplay or this in the Cubric store.</p>
                </div>
                </Accordion>

                        
                <h2 className="accordion__section-title">Safety &amp; Security</h2>
                <Accordion
                className="accordion"
                openClassName="accordion__item--open"
                >
                <div data-header="What safety features exist to protect players?" className="accordion__item">
                    <p className="accordion__answer">
                    Using your account, parents can create a parental pin and control which features players have access to, so they can feel comfortable and confident. 
                    </p>
                </div>
                <div
                    data-header="How do I manage privacy settings?"
                    className="accordion__item"
                >
                    <p className="accordion__answer">Originator values the privacy of every player. For an in-depth look at our privacy policy, <a href="/privacypolicy/" rel="nofollow noreferrer" target="_blank">visit here</a>.</p>
                </div>
                <div
                    data-header="What should I do if I witness or experience bullying, harrassment or offensive material while playing?"
                    className="accordion__item"
                >
                    <p className="accordion__answer">If you experience or witness bullying, harassment or offensive material during a play session, please report it to us using the in-app support feature or <a href="mailto:support@cubric.com">send an email to support@cubric.com</a>. Cubric is meant to be a safe space for all players to enjoy, and we will address any player behavior that violates our community standards and guidelines.</p>
                </div>
                <div
                    data-header="How does the game handle personal data and information?"
                    className="accordion__item"
                >
                    <p className="accordion__answer">For a closer look at our privacy policy, <a href="/privacypolicy/" target="_blank" rel="noreferrer nofollow noopener">visit here</a>.</p>
                </div>
                </Accordion>

                
                <h2 className="accordion__section-title">Technical Issues &amp; Support</h2>
                <Accordion
                className="accordion"
                openClassName="accordion__item--open"
                >
                <div data-header="What do I do if the game is lagging or crashing?" className="accordion__item">
                    <p className="accordion__answer">
                    To report an issue, please contact customer service using the in-app support feature or by <a href="mailto:support@cubric.com">sending an email to support@cubric.com</a>. Please be patient as the app is in beta mode and is therefore more likely to experience performance issues. We also ask that you give our team time to resolve an issue before leaving a negative review.
                    </p>
                </div>
                <div
                    data-header="How can I update the app to the latest version?"
                    className="accordion__item"
                >
                    <p className="accordion__answer">Visit your digital store to ensure you are playing with the latest build. Particularly during the soft launch phase of an app, it is not uncommon to have frequent updates to ensure the best possible player experience.</p>
                </div>
                <div
                    data-header="Where can I find trouble-shooting tips and other advice?"
                    className="accordion__item"
                >
                    <p className="accordion__answer">Visit our YouTube channel for helpful tips and tricks.</p>
                </div>
                <div
                    data-header="How do I contact customer support for assistance?"
                    className="accordion__item"
                >
                    <p className="accordion__answer">Use the in-app support feature or <a href="mailto:support@cubric.com">send an email to support@cubric.com</a>.</p>
                </div>
                <div
                    data-header="is the app available in languages other than English?"
                    className="accordion__item"
                >
                    <p className="accordion__answer">At this time, Cubric is available in US English.</p>
                </div>
                <div
                    data-header="Can the app be used offline?"
                    className="accordion__item"
                >
                    <p className="accordion__answer">Due to the multiplayer nature of the game, Cubric can only be played online.</p>
                </div>
                </Accordion>


            </div>  


        </section>

        <ButtonSection handleDownloadClick={handleDownloadClick} buttonText={"Download Now"} buttonLocation={"FAQ page bottom"} androidUrl={androidUrl} appleUrl={appleUrl} />
        <SocialBox title="Follow Cubric" />       
        <Footer />
    </>
    )

}