import Home from './components/Home/Home';
import NewsLanding from './components/News/NewsLanding';
import News from './components/News/News';
import NewsArticle from './components/News/NewsArticle';
import FAQ from './components/FAQ/FAQ';
import {Routes, Route, useLocation} from "react-router-dom";
import {useEffect, useState} from 'react';
import {gotostore, handleDownloadClick} from './utils/gotostore.js';

import './App.css';

function App() {

  let currSearch = useLocation().search;

  const [appleUrl, setAppleUrl] = useState("");
  const [androidUrl, setAndroidUrl] = useState("");
  const [utmValue, setUtmValue] = useState("");

  const DEFAULT_APPLE_STORE = "https://cubric.onelink.me/PZuq/43j2ygtl";
  const DEFAULT_ANDROID_STORE = "https://cubric.onelink.me/PZuq/43j2ygtl";

  // Extract the utm_source param
  useEffect(() => {
        
    // convert search params keys to lower case
    const searchParams = new URLSearchParams(currSearch);
    const newParams = new URLSearchParams(); // holds the lower case version of the params
    for (const [name, value] of searchParams) { 
      newParams.append(name.toLowerCase(), value);
    }
    // Now get value of utm_source param
    setUtmValue(newParams.get('utm_source'));

  }, [currSearch]);

  /*
  * Set the store URLs and do redirects in new tab
  * 
  * IMPORTANT: When user needs to be redirected without ever seeing the actual site, then we handle it in index.html w/ a separate script (redirectfirst.js).
  * Do it that way to ensure site is never visible / never loads before the redirect (Whereas redirects done in react components usually show site briefly)
  * So, the only redirects we handle here are ones that open a new tab and thus require site to load anyways.
  */
  useEffect(() => {
    /* 
    * IMPORTANT: When triggering the redirect, use the actual literal url string (or a const), not the state var. Why? Because
    * we don't know when the setState fxn has completed, so the state var may be empty at the time of redirect.
    * So we redirect with string literal, while the setState can run async which is used on the urls on the page
    */
    if (utmValue && utmValue.length > 0){
      switch(utmValue) {
        case "gotostore":
          gotostore(false);
          break;
        case "qr01-DO-NOT-USE":
          setAppleUrl("https://pawpatrolacademyappstore.onelink.me/8QSn/l087zrw6");
          setAndroidUrl("https://pawpatrolacademyandroid.onelink.me/f1Ym/zd3vfyuo");
          // gotostoreDirectly(false, "https://pawpatrolacademy.onelink.me/7Y9X/1cq9i8w2"); // this link autodetects device
          break;
        default:
          // set default store URLs since we don't recognise the 
          // REPLACE THIS WITH APPSFLYER IF NECESSARY
          setAppleUrl(DEFAULT_APPLE_STORE);
          setAndroidUrl(DEFAULT_ANDROID_STORE);
          break;
      }
    } else {
      // Set default store URLs if nothing above matches
      setAppleUrl(DEFAULT_APPLE_STORE);
      setAndroidUrl(DEFAULT_ANDROID_STORE);
    } 
    
  }, [utmValue])


  return (
    <>
    <a href="#main-content" class="skip-to-main-content-link">Skip to main content</a>

    <Routes>
      <Route index element={<Home appleUrl={appleUrl} androidUrl={androidUrl} />} />
      {/* <Route path='/test' element={<Test />} /> */}
      <Route path="news" element={<News appleUrl={appleUrl} androidUrl={androidUrl} handleDownloadClick={handleDownloadClick} />}>
        <Route path="" element={<NewsLanding appleUrl={appleUrl} androidUrl={androidUrl} handleDownloadClick={handleDownloadClick} />} />;
        <Route path="post/:articleId" element={<NewsArticle appleUrl={appleUrl} androidUrl={androidUrl} handleDownloadClick={handleDownloadClick} />} />;
        {/* <Route path="post-preview/:articleId" element={<NewsArticlePreview appleUrl={appleUrl} androidUrl={androidUrl} />} />; */}
        {/* <Route path="category/:categoryId" element={<News mode="category" appleUrl={appleUrl} androidUrl={androidUrl} />} />;  */}
      </Route>
      <Route path="faq" element={<FAQ appleUrl={appleUrl} androidUrl={androidUrl} handleDownloadClick={handleDownloadClick} />}></Route>
    </Routes>
    </>
    
  );
}

export default App;
