import {useState, useEffect} from 'react';
import {useParams, Link} from 'react-router-dom';
import axios from 'axios';
import DOMPurify from 'dompurify';

import './NewsArticle.scss';
import MetaSEO from '../MetaSEO/MetaSEO';
import ErrorMessage from '../ErrorMessage/ErrorMessage.jsx';
import CategoryName from '../CategoryName/CategoryName.jsx';
import GetDate from '../GetDate/GetDate.jsx';
import NavBar from '../NavBar/NavBar.jsx';
import {postCache} from '../../utils/apicache.js';

import LoadingAnimation from '../../assets/images/misc/loading.gif';
import ShareIcon from '../../assets/images/misc/share-blue.webp';

// import {
//   EmailIcon,
//   EmailShareButton,
//   FacebookIcon,
//   FacebookShareButton,
//   RedditIcon,
//   RedditShareButton,
//   WhatsappIcon,
//   WhatsappShareButton,
// } from "react-share";

const NewsArticle = ({handleDownloadClick}) => {

  const {articleId} = useParams();
  const [articleContent, setArticleContent] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    
    // setIsError(false);  
    window.scrollTo(0,0);
      
      // Prevents DOMPurify from stripping out target="_blank" in the article body when it sanitizes it.
      DOMPurify.setConfig({ ADD_TAGS: ["iframe"], ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling','target'] });

  }, [])

  // Get the article
  useEffect(() => {
    let idNumber = parseInt(articleId);
    if (Number.isInteger(idNumber) && idNumber >= 0) {
      if (postCache[articleId]) {
        // console.log("found cache, so setting that as article");
        setArticleContent(postCache[articleId]);
      } else {
        setIsLoading(true);
        axios.get(`https://cubricwebsitearticleserver.azurewebsites.net/posts/${articleId}`)
        // axios.get(`http://localhost:8000/posts/${articleId}`)
        .then(response => {
          setIsLoading(false);
          // console.log("No cache, successful api call so setting that as new cache")
          setArticleContent(response.data);
          postCache[articleId] = response.data;
        }, error => {
          setIsLoading(false);
          // console.log("Encountered error fetching article: ", error.message);
          setIsError(true);
        })
      }
      
    } else {
      console.log("Invalid article id format provided");
      setIsError(true);
    }
  }, [articleId])



  if (articleContent) {
    return (
    <>
      <MetaSEO
        title={`${articleContent.title.rendered} | News Article | Cubric Mobile Game | 3D World Builder with Multiplayer App | iOS`}
        canonical={`https://www.cubric.com/news/post/${articleContent.id}`}
        description={`${articleContent.excerpt.rendered}`}
        keywords="News, updates, tips, strategies, guides, min max, optimized builds, cubric,games,game,app,mobile,players,sandbox,3D,avatar,craft,puzzles,roblox,build,world,blocks,multiplayer,friends,coding,minecraft,pkxd,minigames,world-builder,new,obby,island,characters,blueprints,free,ftp,creative"
        type="webapp"
        name="Originator Kids | Spin Master"
        isFAQ={false}
      />

      <NavBar spacerMode={true} />
      <article className="news-post" id="main-content">
        <section className="news-post__header">
            <p className={`news-post__category category-color-${articleContent.categories[0]}`}><CategoryName catId={articleContent.categories[0]} /></p>
            <h1 className="news-post__title" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(articleContent.title.rendered)}}></h1>
            <p className="news-post__date"><GetDate theDate={articleContent.acf.displaydate} /></p>
            <img className="news-post__splash-image" src={articleContent.acf.splashimageurl} alt="accompanying img for article" />
        </section>
        <section className="news-post__body" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(articleContent.content.rendered)}}>
        </section>
        <section className="news-post__share-box">
          {/* <div className="news-post__share-label">
            <img className="news-post__share-icon" src={ShareIcon} alt="Share this article" />
            &nbsp;Share
          </div>
          <div className="news-post__share-buttons-box">
            <EmailShareButton
              url={window.location.href}
              subject={`${articleContent.title.rendered} article`}
              body="Check out this article from PAW Patrol Academy: "
              className="news-post__share-button"
            >
              <EmailIcon size={32} round />
            </EmailShareButton>
            <FacebookShareButton
              url={window.location.href}
              title={articleContent.title.rendered}
              className="news-post__share-button"
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <WhatsappShareButton
              url={window.location.href}
              title={articleContent.title.rendered}
              separator=":: "
              className="news-post__share-button"
            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            <RedditShareButton
              url={window.location.href}
              title={articleContent.title.rendered}
              windowWidth={660}
              windowHeight={460}
              className="news-post__share-button"
            >
              <RedditIcon size={32} round />
            </RedditShareButton>
          </div> */}
          <Link className="news-post__all-articles-link" to={`/news${window.location.search}`}>
          &#9668; See all articles
            {/* <img className="news-post__all-articles-link-icon" src="/images/go_up_icon.svg" alt="go up" />  */}
          </Link>
        </section>
      </article>
      </>
    )
  }

  // Error fetching article (either id is improper format or no matching article or network error)
  if (isError) {
    return (
      <ErrorMessage errorMessage="No matching article found" />
    )
  }

  if (isLoading) {
    // REPLACE THIS WITH LOADING ANIMATION
    return (
      <div className="news-post__loading-container">
        <img className="news-post__loading-image" src={LoadingAnimation} alt="Content is loading" />
      </div>
    )
  }
}
export default NewsArticle;