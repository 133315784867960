import './SplashBig.scss';

// import CubricLogo from '../../assets/images/cubric-logo.webp';
// import SplashVideo320 from '../../assets/videos/cubric_splash_640.webm';
import SplashVideo320h264 from '../../assets/videos/cubric_splash_640.mp4';
// import SplashVideo1920 from '../../assets/videos/cubric_splash_1920.webm';
import SplashVideo1920h264 from '../../assets/videos/cubric_splash_1920.mp4';
import SplashImageMobile from '../../assets/images/splash_fallback_small.webp';
import SplashImageDesktop from '../../assets/images/splash_fallback_big.webp';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export default function SplashBig({handleDownloadClick, appleUrl, androidUrl}) {

    const [isMobile, setIsMobile] = useState(null);

    // Capture change of window size. Used to dynamically change number of posts displayed on mobile vs other breakpoints.
    useEffect(() => {
        
        handleResize(); // This handles the initial component mount. Otherwise check would not run at initial page load.

        function handleResize(){
            if (window.innerWidth < 690) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        
        <section className="splash-big">
            { /* SPLASH VIDEO */ }
            <div className="splash__copy-box">
                <div className="splash__copy-box-left-shape"></div>
                <div className="splash__copy-box-right-shape"></div>

                
                    <div className="splash__title" role="heading" aria-level="2"><Link className="splash__text-link" onClick={() => handleDownloadClick("heroSplashArea", appleUrl, androidUrl)}>Get Early Access</Link></div>
                    <div className="splash__subtitle" role="heading" aria-level="2"><Link className="splash__text-link" onClick={() => handleDownloadClick("heroSplashArea", appleUrl, androidUrl)}>Beta Testers Welcome</Link></div>
                
                <button className="download-button splash__button" onClick={() => handleDownloadClick("heroSplashArea", appleUrl, androidUrl)}>Play Now</button>
                {/* <img className="splash__logo" src={CubricLogo} alt="Cubric Logo" /> */}
            </div>
            
            {(isMobile === true)
            ? <><video className="splash__video--mobile"
                poster={SplashImageMobile}
                preload='metadata'
                autoPlay
                loop
                muted
                playsInline
                >
                {/* <source src={SplashVideo320} type="video/webm" /> */}
                <source src={SplashVideo320h264} type="video/mp4" preload='metadata' />
                </video>
                </>
            : ""}
            {(isMobile === false) // We test false specifically & not just null as isMobile is always null initially, causing page to load the big vid
            ? <><video className="splash__video--tablet"
                poster={SplashImageDesktop}
                preload='metadata'
                autoPlay
                loop
                muted
                playsInline
                >
                {/* <source src={SplashVideo1920} type="video/webm" /> */}
                <source src={SplashVideo1920h264} type="video/mp4" preload='metadata' />
              </video>
              </>
            : "" }
        </section>


    )
    
}