import './SocialBox.scss';
import instagramIcon from '../../assets/images/social/logo-instagram.svg';
import facebookIcon from '../../assets/images/social/logo-facebook.svg';
import youtubeIcon from '../../assets/images/social/logo-youtube.svg';
import discordIcon from '../../assets/images/social/logo-discord.svg';
import tiktokIcon from '../../assets/images/social/logo-tiktok.svg';

const SocialBox = ({title}) => {

    return (
        <section className="social-box">
            <div className="social__title" role="heading" aria-level="3">
                {(title) ? title : "Follow Cubric"}
            </div>
            <div className="social__icons-box">
                <a className="social__icons-link" aria-label="Link to Instagram (Opens in new tab)" href="https://www.instagram.com/playcubric/" target="_blank" rel="noreferrer nofollow noopener">
                    <img src={instagramIcon} className="social__icons-img" alt="link to Instagram" />
                </a>
                <a className="social__icons-link" aria-label="Link to Youtube (Opens in new tab)" href="https://www.youtube.com/channel/UCpkHeyWGwAUI475fCwRtTPA" target="_blank" rel="noreferrer nofollow noopener">
                    <img src={youtubeIcon} className="social__icons-img" alt="link to YouTube" />
                </a>
                <a className="social__icons-link" aria-label="Link to Facebook (Opens in new tab)" href="https://www.facebook.com/PlayCubric" target="_blank"  rel="noreferrer nofollow noopener">
                    <img src={facebookIcon} className="social__icons-img" alt="link to Facebook" />
                </a>
                <a className="social__icons-link" aria-label="Link to Tiktok (Opens in new tab)" href="https://www.tiktok.com/@playcubric" target="_blank"  rel="noreferrer nofollow noopener">
                    <img src={tiktokIcon} className="social__icons-img" alt="link to TikTok" />
                </a>
                <a className="social__icons-link" aria-label="Link to Discord (Opens in new tab)" href="https://discord.gg/SyewvEsMYX" target="_blank"  rel="noreferrer nofollow noopener">
                    <img src={discordIcon} className="social__icons-img" alt="link to Discord" />
                </a>
            </div>
        </section>

    )

}
export default SocialBox;