// Before hardcode 

import {useEffect} from 'react';
import {Outlet} from 'react-router-dom';
// import MetaSEO from "../components/MetaSEO/MetaSEO";
// import Header from "./Header";
import NavBar from '../NavBar/NavBar';
import Footer from "../Footer/Footer";

const News = ({mode, appleUrl, androidUrl, handleDownloadClick}) => {

      useEffect(() => {
        window.scrollTo(0,0);
    }, [])


  return (
    <>
      {/* <MetaSEO
        title="Article | PAW Patrol Academy | Preschool Learning App | iOS & Android"
        canonical="https://www.pawpatrolacademy.com/faq"
        description="FAQ Frequently Asked Questions about the Paw Patrol Academy learning app for kids available on Apple iOS and Android devices.  Unleash all the fun and adventure of PAW Patrol in a world of learning that kids love and parents trust.  Featuring heroic missions, real, educational content and more."
        keywords="Learning App, Learning games, Education App, ABC, Coloring app, Alphabet, Spelling, Academy,Preschool app,Playtime,Fun,Adventure,Shapes,Music,Dance,Kindergarten app,Chase,Skye, Rubble, The Lookout,Paw patrol"
        type="webapp"
        name="Originator Kids | Spin Master"
        isFAQ={true}
      /> */}
      {/* <Header pageType="news" appleUrl={appleUrl} androidUrl={androidUrl} /> */}
      
      <NavBar androidUrl={androidUrl} applUrl={appleUrl} handleDownloadClick={handleDownloadClick} />
      <Outlet />
      <Footer />
    </>
  );
};

export default News;

