// import MetaSEO from '../MetaSEO/MetaSEO';
import {useEffect, useState} from 'react';
import { newsListCache } from '../../utils/apicache';
import axios from 'axios';
import './NewsLanding.scss';
import MetaSEO from '../MetaSEO/MetaSEO';
import NewsCarousel from './NewsCarousel';
import SocialBox from '../SocialBox/SocialBox';
import ButtonSection from '../ButtonSection/ButtonSection';

// Lists posts on the news landing page.  Currently just grabs all posts.  Will eventually need to update to be able to paginate & show categories
const NewsLanding = ({appleUrl, androidUrl, handleDownloadClick}) => {

    const [resultsData, setResultsData] = useState(null);

    useEffect(() => {
        window.scrollTo(0,0);
        
        if (newsListCache["allPosts"]) {
            // see if already have a cached response.  If so, use it.
            setResultsData(newsListCache["allPosts"]);
        } else {
            axios.get("https://cubricwebsitearticleserver.azurewebsites.net/postslist/32/")
            .then(response => {
                // console.log("here is response.data: ", response.data);
                setResultsData(response.data);
                newsListCache["allPosts"] = response.data;
            }, err => {
                console.log("**** error retrieving posts list:", err.response.status, err.response.statusText);
            })
        }
    }, [])   
        
    return (
        <>

            <MetaSEO 
            title="News, Guides, Tips, Updates, Strategies, Blog | Cubric Mobile Game | 3D World Builder with Multiplayer App | iOS"
            canonical="https://www.cubric.com/news"
            description="The latest news, strategy guides, tips, updates, articles and more for Cubric, a multiplayer, 3D world builder mobile app game designed to unleash creativity, spark community and challenge the competitive spirit of gamers of all ages."
            keywords="News, updates, tips, strategies, guides, min max, optimized builds, cubric,games,game,app,mobile,players,sandbox,3D,avatar,craft,puzzles,roblox,build,world,blocks,multiplayer,friends,coding,minecraft,pkxd,minigames,world-builder,new,obby,island,characters,blueprints,free,ftp,creative"
            type="webapp"
            name="Originator Kids | Spin Master"
            isFAQ={false}
            />
            <div className="news-landing__banner" id="main-content"></div>
            <section className="news-landing">
                <h1 className="news-landing__title">Cubric News and Updates</h1>
                <NewsCarousel resultsData={resultsData} />
            </section>
            <ButtonSection buttonText="Download Now" buttonLocation="News Landing Page lower button" handleDownloadClick={handleDownloadClick} androidUrl={androidUrl} appleUrl={appleUrl} />
            <SocialBox title="Follow Cubric" />
        </>
        
    )
}

export default NewsLanding;