import { Helmet } from 'react-helmet-async';



const MetaSEO = ({ title, canonical, description, keywords, type, name, isFAQ, noindex }) => {

    
  return (
    <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={canonical} />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:image" content="https://dev.cubric.com/images/articles/2024/06/splash.webp" />
        <meta property="og:type" content={type} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={canonical} />
        <meta name="twitter:creator" content={name} />
        <meta name="twitter:card" content={type} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        {(noindex === true) ? <meta name="robots" content="noindex" /> : ""}
        <script type="application/ld+json">
            {(!isFAQ) ? 
            JSON.stringify({
                "@context": "https://schema.org",
                "@graph": [
                {
                "@type": "MobileApplication",
                "name": "Cubric",
                "publisher": {
                    "@type": "Organization",
                    "name": ["Originator", "Spin Master"]
                },
                "operatingSystem": ["iOS","ANDROID"],
                "downloadUrl": [
                    "https://apps.apple.com/mx/app/cubric/id1600377342?mt=8",
                    "https://play.google.com/store/apps/details?id=com.originatorkids.stem"
                ],
                "applicationCategory": ["GameApplication","EntertainmentApplication"],
                "offers": {
                    "@type": "Offer",
                    "price": "0.00",
                    "priceCurrency": "USD"
                }
                },
                {
                "@type": "VideoGame",
                "name": "Cubric",
                "publisher": {
                    "@type": "Organization",
                    "name": ["Originator", "Spin Master"]
                },
                "operatingSystem": ["iOS","ANDROID"],
                "downloadUrl": [
                    "https://apps.apple.com/mx/app/cubric/id1600377342?mt=8",
                    "https://play.google.com/store/apps/details?id=com.originatorkids.stem"
                ],
                "applicationCategory": ["GameApplication","EntertainmentApplication"],
                "offers": {
                    "@type": "Offer",
                    "price": "0.00",
                    "priceCurrency": "USD"
                }
                }
                ]
            })
            : 
            JSON.stringify({
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                    "@type": "Question",
                    "name": "What is Cubric?","acceptedAnswer": 
                    { "@type": "Answer", "text": "Cubric is the ultimate 3D world building app that celebrates creators, connection and competition.  Every player's experience is unique by design, so Cubric is truly what our fans make it. Players can create their own reality with custom builds and obstacle courses and then share these designs with fellow players.  While blueprints make the creation process easy, players can bypass building and explore Cubric Island's many attractions and mini games instead.  Trust us when we say that Cubric is the world you've been waiting for."}
                    },
                    {
                    "@type": "Question",
                    "name": "When and where is the app available to download?","acceptedAnswer": 
                    { "@type": "Answer", "text": "Cubric is now available globally to players with iOS devices.  Please check the App Store for device compatibility information. Please subscribe to our social media channels for news and updates, including the latest info on our Android release dates. "}
                    },
                    {
                    "@type": "Question",
                    "name": "How do I download it?","acceptedAnswer": 
                    { "@type": "Answer", "text": "Visit the App Store to confirm eligibility and device compatibility."}
                    },
                    {
                    "@type": "Question",
                    "name": "Is the app free to download?","acceptedAnswer": 
                    { "@type": "Answer", "text": "The app is free to download on iOS globally, though certain features will require in-app purchases."}
                    },
                    {
                    "@type": "Question",
                    "name": "What does beta testing mean?  Does that mean the app is not safe to download?","acceptedAnswer": 
                    { "@type": "Answer", "text": "As part of our commitment to delivering the best possible gaming experience, we are currently in a soft launch phase or beta testing mode. While the game is accessible to the general public, we are actively gathering feedback, fine-tuning gameplay mechanics, and making optimizations to improve the experience for all players. We appreciate your patience."}
                    },
                    {
                    "@type": "Question",
                    "name": "Where can I find trouble-shooting tips and other advice?","acceptedAnswer": 
                    { "@type": "Answer", "text": "Visit our YouTube channel for helpful tips and tricks."}
                    },
                    {
                    "@type": "Question",
                    "name": "How do I contact customer support for assistance?","acceptedAnswer": 
                    { "@type": "Answer", "text": "Use the in-app support feature"}
                    },
                    {
                    "@type": "Question",
                    "name": "is the app available in languages other than English?","acceptedAnswer": 
                    { "@type": "Answer", "text": "At this time, Cubric is available in US English"}
                    },
                    {
                    "@type": "Question",
                    "name": "Can the app be used offline?","acceptedAnswer": 
                    { "@type": "Answer", "text": "Due to the multiplayer nature of the game, Cubric can only be played online."}
                    },
                    {
                    "@type": "Question",
                    "name": "What safety features exist to protect players?","acceptedAnswer": 
                    { "@type": "Answer", "text": "Using your account, parents can create a parental pin and control which features players have access to, so they can feel comfortable and confident. "}
                    },
                    {
                    "@type": "Question",
                    "name": "How do I manage privacy settings?","acceptedAnswer": 
                    { "@type": "Answer", "text": "Originator values the privacy of every player.  For an in-depth look at our privacy policy, visit here."}
                    },
                    {
                    "@type": "Question",
                    "name": "What should I do if I witness or experience bullying, harrassment or offensive material while playing?","acceptedAnswer": 
                    { "@type": "Answer", "text": "If you experience or witness bullying, harassment or offensive material during a play session, please contact to report it.  Cubric is meant to be a safe space for all players to enjoy, and we will address any player behavior that violates our community standards and guidelines."}
                    },
                    {
                    "@type": "Question",
                    "name": "How does the game handle personal data and information?","acceptedAnswer": 
                    { "@type": "Answer", "text": "For a closer look at our privacy policy, visit here"}
                    },
                    {
                    "@type": "Question",
                    "name": "What is the Cubric Account System?","acceptedAnswer": 
                    { "@type": "Answer", "text": "The Cubric Account System, which allows the company to identify player by email, ensures customer service issues are resolved quickly and accurately.  "}
                    },
                    {
                    "@type": "Question",
                    "name": "How do I change my password?","acceptedAnswer": 
                    { "@type": "Answer", "text": "You can change your password in settings."}
                    },
                    {
                    "@type": "Question",
                    "name": "How do I make in-app purchases?","acceptedAnswer": 
                    { "@type": "Answer", "text": "Coins can be purchased in the coin store which can be accessed by tapping this during gameplay or this in the Cubric Store."}
                    }
                ]    
            })
            }
        </script>

    
    </Helmet>
  );
};

MetaSEO.defaultProps = {
    isFAQ: false,
};

export default MetaSEO;