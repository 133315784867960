import './NavBar.scss';
import {Link, useLocation} from 'react-router-dom';
import { useState } from 'react';
import CubricLogo from '../../assets/images/cubric-logo.webp';

import hamburgerButton from '../../assets/images/misc/hamburger.gif'; 
import closeButton from '../../assets/images/misc/close_icon.gif'; 

export default function NavBar({androidUrl, appleUrl, handleDownloadClick, pageMode, spacerMode}) {

    const {pathname} = useLocation();
    const [hamburgerState, setHamburgerState] = useState(true);  
    // closes/opens the mobile nav menu
    const handleNavMenuClick = () => {
    // When used by nav menu links that go to a section, ensures the expanded mobile menu is closed
    setHamburgerState(!hamburgerState);
}

    return (

        // <nav className={`navbar ${(hamburgerState) ? "" : "navbar--solid"} ${(pageMode === "home") ? "" : "navbar--non-home"}`}>
        <nav className={`navbar ${(hamburgerState) ? "" : "navbar--solid"} ${(spacerMode === true) ? "navbar--spacer-mode" : ""}`}>

                <Link to={`/${window.location.search}`} className="navbar__logo-link" onClick={() => (window.scrollTo(0,0))}><img className="navbar__logo-image" src={CubricLogo} alt="Cubric logo" /></Link>
                
                <img className="navbar__hamburger-button" src={`${(hamburgerState) ? hamburgerButton : closeButton}`} alt="hamburger menu" onClick={handleNavMenuClick} />
                <div className={`navbar__content-box ${(hamburgerState) ? "navbar__content-box--hide" : ""}`}>
                    <div className="navbar__content-item">
                        <Link className={`navbar__nav-link ${(pathname === "/") ? "navbar__nav-link--current" : ""}`} to={`/${window.location.search}`} onClick={() => handleNavMenuClick()}>Home</Link>
                    </div>
                    <div className="navbar__content-item">
                        <Link className={`navbar__nav-link ${(pathname === "/news" || pathname === "/news/") ? "navbar__nav-link--current" : ""}`} to={`/news${window.location.search}`} onClick={() => handleNavMenuClick()}>News</Link>
                    </div>
                    <div className="navbar__content-item">
                        <Link className={`navbar__nav-link ${(pathname === "/faq") ? "navbar__nav-link--current" : ""}`} to={`/faq${window.location.search}`} onClick={() => handleNavMenuClick()}>FAQ</Link>
                    </div>
                    <div className="navbar__content-item navbar__content-button-box">
                        <button className="download-button navbar__content-button" onClick={() => handleDownloadClick("navbar", appleUrl, androidUrl)}>Play Now</button>
                    </div>   
                </div>
        </nav>
    )

}