import './Slogan.scss';
import { useRef } from 'react';
// import PresentsVideo1 from '../../assets/videos/slogan/presents1.webm';
import PresentsVideo1h264 from '../../assets/videos/slogan/presents1.mp4';
// import PresentsVideo2 from '../../assets/videos/slogan/presents2.webm';
import PresentsVideo2h264 from '../../assets/videos/slogan/presents2.mp4';
import PresentsImage1 from '../../assets/images/slogan/presents1.webp';
import PresentsImage2 from '../../assets/images/slogan/presents2.webp';
import AppleLogo from '../../assets/images/misc/logo_apple.svg';

export default function Slogan({handleDownloadClick, appleUrl, androidUrl}) {

    const bgVideo1Ref = useRef();
    const bgVideo2Ref = useRef();

    function slowdownVideos() {
        bgVideo1Ref.current.playbackRate = 0.76;
        bgVideo2Ref.current.playbackRate = 0.76;

    }
    return (
        <section className="slogan">

            <div className="slogan__bg-left">
                <video playsInline className="slogan__bg-video"
                    ref={bgVideo1Ref}
                    onCanPlay={slowdownVideos}
                    poster={PresentsImage1}
                    autoPlay
                    loop
                    muted
                >
                    {/* <source src={PresentsVideo1} type="video/webm" /> */}
                    <source src={PresentsVideo1h264} type="video/mp4" />
                </video>
            </div>
            <div className="slogan__bg-right">
                <video playsInline className="slogan__bg-video"
                    ref={bgVideo2Ref}
                    onCanPlay={slowdownVideos}
                    poster={PresentsImage2}
                    autoPlay
                    loop
                    muted
                >
                    {/* <source src={PresentsVideo2} type="video/webm" /> */}
                    <source src={PresentsVideo2h264} type="video/mp4" />
                </video>
            </div>
            <h1 className="slogan__title">Your world is waiting&nbsp;for you
                {/* The world's best block party is waiting&nbsp;for&nbsp;you!. */}
            </h1>
            <h2 className="slogan__subtitle">
                Available globally for<br />
                <img className="slogan__apple-logo" src={AppleLogo} alt="Apple iOS logo" /><span className="slogan__subtitle-emphasized">iOS devices</span>
            </h2>
            <button className="download-button slogan__button" onClick={() => handleDownloadClick("Slogan section on Cubric.com", appleUrl, androidUrl)}>Play Now</button>
            

        </section>
    )
}
