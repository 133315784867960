import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import NewsCarousel from './NewsCarousel';
import {homePostsCache} from '../../utils/apicache';
import './HomeNews.scss';

export default function HomeNews() {
    
    const [resultsData, setResultsData] = useState(null);
    const [isMobile, setIsMobile] = useState(null);

    // Capture change of window size. Used to dynamically change number of posts displayed on mobile vs other breakpoints.
    useEffect(() => {
        
        handleResize(); // This handles the initial component mount. Otherwise check would not run at initial page load.

        function handleResize(){
            if (window.innerWidth < 690) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        
        if (homePostsCache["homePosts"]) {
            // console.log("using results cache on homepage list");
            setResultsData(homePostsCache["homePosts"]);
        } else {

            // Show more posts if on mobile as layout does not need to be in triplets
            let numPosts;
            if (isMobile) {
                numPosts = 4;
            } else {
                numPosts = 3;
            }
            
            
            axios.get("https://cubricwebsitearticleserver.azurewebsites.net/postslist/"+numPosts)
            .then(response => {
                setResultsData(response.data);
                homePostsCache["homePosts"] = response.data;
            }, err => {
                // console.log("****** error retrieving posts list: ", err.response.status, err.response.statusText); 
                console.log("****** error retrieving posts list: ", err.response); 
            })
        }
    }, [isMobile])

    return (
        <section className="home-news">
            <Link className="home-news__title-link" to={`/news/${window.location.search}`}>
                <h2 className="home-news__title">Cubric News and Updates</h2>
            </Link>
            <NewsCarousel resultsData={resultsData} />
        </section>
    )
}