import { useState, React, useRef, memo } from "react";
import { StackedCarousel,ResponsiveContainer } from "react-stacked-center-carousel";
import './BuildCarousel.scss';

import closeButton from '../../assets/images/misc/close_icon.svg'; 
import BuildImg1 from '../../assets/images/buildscarousel/Image_Build_1.webp';
import BuildImg2 from '../../assets/images/buildscarousel/Image_Build_2.webp';
import BuildImg3 from '../../assets/images/buildscarousel/Image_Build_3.webp';
import BuildImg4 from '../../assets/images/buildscarousel/Image_Build_4.webp';
import BuildImg5 from '../../assets/images/buildscarousel/Image_Build_5.webp';
import BuildImg6 from '../../assets/images/buildscarousel/Image_Build_6.webp';
import BuildImg7 from '../../assets/images/buildscarousel/Image_Build_7.webp';
import BuildImg8 from '../../assets/images/buildscarousel/Image_Build_8.webp';

import ThumbBuildImg1 from '../../assets/images/buildscarousel/thumb/Image_Build_1.webp';
import ThumbBuildImg2 from '../../assets/images/buildscarousel/thumb/Image_Build_2.webp';
import ThumbBuildImg3 from '../../assets/images/buildscarousel/thumb/Image_Build_3.webp';
import ThumbBuildImg4 from '../../assets/images/buildscarousel/thumb/Image_Build_4.webp';
import ThumbBuildImg5 from '../../assets/images/buildscarousel/thumb/Image_Build_5.webp';
import ThumbBuildImg6 from '../../assets/images/buildscarousel/thumb/Image_Build_6.webp';
import ThumbBuildImg7 from '../../assets/images/buildscarousel/thumb/Image_Build_7.webp';
import ThumbBuildImg8 from '../../assets/images/buildscarousel/thumb/Image_Build_8.webp';

import ArrowBackIcon from "../../assets/images/buildscarousel/arrow.svg";
import ArrowForwardIcon from "../../assets/images/buildscarousel/arrow.svg";


export default function BuildCarousel() {

    // Both state & fxn below are used by the pagination dots in carousel 
    const [centerSlideDataIndex, setCenterSlideDataIndex] = useState(0);
    const onCenterSlideDataIndexChange = (newIndex) => {
        setCenterSlideDataIndex(newIndex);
    };

    const [imageIndex, setImageIndex] = useState(0);
    const [showModal, setShowModal] = useState(false);

    function handleThumbClick(imageIndex) {
        setImageIndex(imageIndex);
        setShowModal(true);
    }
    function closeModal() {
        setShowModal(false);
    }

    const imageArray = [
        {
            thumb: ThumbBuildImg1,
            image: BuildImg1
        },
        {
            thumb: ThumbBuildImg2,
            image: BuildImg2
        },
        {
            thumb: ThumbBuildImg3,
            image: BuildImg3
        },
        {
            thumb: ThumbBuildImg4,
            image: BuildImg4
        },
        {
            thumb: ThumbBuildImg5,
            image: BuildImg5
        },
        {
            thumb: ThumbBuildImg6,
            image: BuildImg6
        },
        {
            thumb: ThumbBuildImg7,
            image: BuildImg7
        },
        {
            thumb: ThumbBuildImg8,
            image: BuildImg8
        }
    ]
    // The card/slide component in the carousel
    // Very import to memoize your Slide component otherwise there might be performance issue
    // At minimum your should do a simple React.memo(SlideComponent)
    const Card = memo(function Card (props) {
        const { data, dataIndex } = props;
        const { thumb } = data[dataIndex];
        return (
            <div
            style={{
                width: "100%",
                height: 300,
                userSelect: "none",
            }}
            className="my-slide-component22"
            >
            <img
                style={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
                borderRadius: 0,
                }}
                draggable={false}
                src={thumb}
                alt="artist build screenshot"
                className="build-carousel__thumb"
                onClick={() => handleThumbClick(dataIndex)}
            />
            </div>
        );
    })

    // Used by carousel container
    const ref = useRef();

    // return <div style={{ height: 15, width: 15, borderRadius: '100%', background: isCenterSlide ? 'black' : 'none', border: '1px solid black' }}/>
    function Pagination({ centerSlideDataIndex }) {
        return (
            <div className="build-carousel__pagination-box">
                {imageArray.map((_, index) => {
                    const isCenterSlide = centerSlideDataIndex === index;
                    return <div className={`build-carousel__pagination-dot ${(isCenterSlide) ? "build-carousel__pagination-dot-active" : ""}`}>&nbsp;</div>
                })}
            </div>
        );
    }
    

    return (

        <section className="build-carousel">

            <h2 className="build-carousel__title">Featured Player Builds</h2>    
            {(showModal)
            ? <section className="build-carousel__modal">
                
                <img className="build-carousel__modal-close-btn-img" src={closeButton} alt="Click to close" onClick={closeModal} />
                
                <img className="build-carousel__modal-image" src={imageArray[imageIndex].image} alt="Example of a beautiful world build in Cubric" />
            </section>
            : null
            }
            <div className="build-carousel__container">
                <ResponsiveContainer
                    carouselRef={ref}
                    render={(parentWidth, carouselRef) => {
                        // If you want to use a ref to call the method of StackedCarousel, you cannot set the ref directly on the carousel component
                        // This is because ResponsiveContainer will not render the carousel before its parent's width is determined
                        // parentWidth is determined after your parent component mounts. Thus if you set the ref directly it will not work since the carousel is not rendered
                        // Thus you need to pass your ref object to the ResponsiveContainer as the carouselRef prop and in your render function you will receive this ref object
                        let currentVisibleSlide = 3;
                        if (parentWidth <= 1440) currentVisibleSlide = 3;
                        if (parentWidth <= 1080) currentVisibleSlide = 3;
                        if (parentWidth <= 689) currentVisibleSlide = 3;
                        return (
                            <StackedCarousel
                            ref={carouselRef}
                            slideComponent={Card}
                            // slideWidth={parentWidth < 800 ? parentWidth - 40 : 350}
                            slideWidth={parentWidth < 590 ? parentWidth*0.9 : 500}
                            carouselWidth={parentWidth}
                            data={imageArray}
                            currentVisibleSlide={currentVisibleSlide}
                            onActiveSlideChange={onCenterSlideDataIndexChange}
                            maxVisibleSlide={5}
                            useGrabCursor
                            />
                        );
                    }}
                />
                <img className="build-carousel__arrow-back" alt="previous item" tabIndex={0} role="button" src={ArrowBackIcon} style={{  position: "absolute", top: "40%", left: 10, zIndex: 10}}
                    onClick={() => {
                        ref.current?.goBack();
                    }}
                />            
                <img className="build-carousel__arrow-forward" alt="next item" tabIndex={0} role="button" src={ArrowForwardIcon} style={{ position: "absolute", top: "40%", right: 10, zIndex: 10 }}
                    size="small"
                    color="primary"
                    onClick={() => {
                        ref.current?.goNext(6);
                    }}
                />
                <Pagination centerSlideDataIndex={centerSlideDataIndex} />
            </div>
        </section>
    )
}