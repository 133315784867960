import "./Home.scss";
import { useEffect } from "react";
import MetaSEO from "../MetaSEO/MetaSEO";
import NavBar from "../NavBar/NavBar";
import SplashBig from "../SplashBig/SplashBig";
import Slogan from "../Slogan/Slogan";
import Trailer from "../Trailer/Trailer";
import SocialBox from "../SocialBox/SocialBox";
import BuildCarousel from "../BuildCarousel/BuildCarousel";
import Features from "../Features/Features";
import HomeNews from "../News/HomeNews";
import ButtonSection from "../ButtonSection/ButtonSection";
import Footer from "../Footer/Footer";

import { handleDownloadClick } from "../../utils/gotostore.js";

function Home({ isHome = null, appleUrl = null, androidUrl = null }) {

  useEffect(() => {
    window.scrollTo(0,0);
  }, [])

  return (
    <>
      <MetaSEO 
      title="Cubric Mobile Game | 3D World Builder Multiplayer App | iOS"
      canonical="https://www.cubric.com/"
      description="Cubric is a multiplayer, 3D world builder, mobile game designed to spark creativity, foster community and challenge the competitive spirit of gamers of all ages"
      keywords="cubric,games,app,mobile,players,sandbox,3D,avatar,craft,puzzles,roblox,build,world,blocks,multiplayer,friends,coding,minecraft,pkxd,minigames,world-builder,new,obby,island,characters,blueprints,free,ftp,creative"
      type="webapp"
      name="Originator Kids | Spin Master"
      />
      <div className="site-container">
        <NavBar
          androidUrl={androidUrl}
          appleUrl={appleUrl}
          handleDownloadClick={handleDownloadClick}
          pageMode="home"
        />
        <div id="main-content"></div>
        <SplashBig
          handleDownloadClick={handleDownloadClick}
          appleUrl={appleUrl}
          androidUrl={androidUrl}
        />
        <Slogan
          handleDownloadClick={handleDownloadClick}
          appleUrl={appleUrl}
          androidUrl={androidUrl}
        />
        <Trailer />
        <SocialBox title="Stay Informed. Make Friends. Get Inspired." />
        <BuildCarousel />
        <Features />
        <HomeNews />
        <ButtonSection
          buttonText="Download Now"
          appleUrl={appleUrl}
          androidUrl={androidUrl}
          handleDownloadClick={handleDownloadClick}
          buttonLocation="Cubric homepage lower button"
        />
        <SocialBox title="Follow Cubric" />
        <Footer />
      </div>
    </>
  );
}

export default Home;
