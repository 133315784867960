import './Features.scss';

import ImageCreate from '../../assets/images/features/image_create.webp';
import ImageConnect from '../../assets/images/features/image_connect.webp';
import ImageCompete from '../../assets/images/features/image_compete.webp';
import FlamingoBG from '../../assets/images/features/flamingo.webp';

import RainbowImg from '../../assets/images/features/emoji_rainbow.webp';
import StarImg from '../../assets/images/features/emoji_star.webp';
import StarAnim from '../../assets/videos/features/emoji_star.mp4';
import RainbowAnim from '../../assets/videos/features/emoji_rainbow.mp4';

import SplatterBurger from '../../assets/images/features/splatter_burger.webp';
import SplatterIcecream from '../../assets/images/features/splatter_icecream.svg';

import { useRef, useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { CustomEase } from "gsap/CustomEase";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CustomEase);

const Features = () => {

    const featuresArea = useRef();

    useLayoutEffect( () => {
        let featuresCtx = gsap.context(() => {

            // gsap.from(".features__item", 
            // {scrollTrigger: {trigger: ".features__item--aplus", start: "bottom bottom", markers: false}, 
            // x: window.innerWidth, 
            // ease:"elastic.out(1, 0.5)", 
            // stagger: 0.3, 
            // duration: 2, 
            // delay: 0.1});
            gsap.from(".features__group--create", 
                {scrollTrigger: {trigger: ".features__group--create", start: "top bottom", markers: false}, 
                x: window.innerWidth*0.5, 
                opacity:0,
                ease:"elastic.out(1, 0.7)", 
                // stagger: 0.4, 
                duration: 1, 
                delay: 0.1});
            gsap.from(".features__group--connect", 
                {scrollTrigger: {trigger: ".features__group--create", start: "top bottom", markers: false}, 
                x: -window.innerWidth*0.5, 
                opacity:0,
                ease:"elastic.out(1, 0.7)", 
                duration: 1, 
                delay: 0.5});
            gsap.from(".features__group--compete", 
                {scrollTrigger: {trigger: ".features__group--create", start: "top bottom", markers: false}, 
                x: window.innerWidth*0.5, 
                opacity:0,
                ease:"elastic.out(1, 0.7)", 
                duration: 1, 
                delay: 0.9});
            gsap.from(".features__bg-splat-burger", {
                scrollTrigger: {trigger: ".features__group--compete", start: "top center", markers: false},
                opacity: 0,
                // ease: "bounce.out", //"elastic.out(1,0.5)",
                ease: "power4.out", // ease: CustomEase.create("custom", "M0,0 C0,0 0.046,0.09 0.09,0.285 0.119,0.415 0.208,0.72 0.223,0.761 0.246,0.824 0.281,1.014 0.347,1.014 0.424,1.014 0.42,0.937 0.533,0.937 0.634,0.937 0.637,0.98 0.7,1 0.762,1.019 0.79,1.015 0.841,1.016 0.898,1.016 0.927,1 0.981,1 1.022,1 1,1 1,1 "),
                duration: 0.1,
                delay: 1.7
            });
            gsap.fromTo(".features__bg-splat-burger", {
                scrollTrigger: {trigger: ".features__group--compete", start: "top center", markers: false},
                scale: 0.5,
                // ease: "bounce.out",
                ease: "power4.out", // ease: CustomEase.create("custom", "M0,0 C0,0 0.046,0.09 0.09,0.285 0.119,0.415 0.208,0.72 0.223,0.761 0.246,0.824 0.281,1.014 0.347,1.014 0.424,1.014 0.42,0.937 0.533,0.937 0.634,0.937 0.637,0.98 0.7,1 0.762,1.019 0.79,1.015 0.841,1.016 0.898,1.016 0.927,1 0.981,1 1.022,1 1,1 1,1 "),
                duration: 0.5,
                delay: 1.7
                },
                {
                scrollTrigger: {trigger: ".features__group--compete", start: "top center", markers: false},
                scale: 1.0,
                // ease: "bounce.out",
                ease: "power4.out", // ease: CustomEase.create("custom", "M0,0 C0,0 0.046,0.09 0.09,0.285 0.119,0.415 0.208,0.72 0.223,0.761 0.246,0.824 0.281,1.014 0.347,1.014 0.424,1.014 0.42,0.937 0.533,0.937 0.634,0.937 0.637,0.98 0.7,1 0.762,1.019 0.79,1.015 0.841,1.016 0.898,1.016 0.927,1 0.981,1 1.022,1 1,1 1,1 "),
                duration: 0.5,
                delay: 1.7
                }
            );
            gsap.from(".features__bg-splat-ice", {
                scrollTrigger: {trigger:  ".features__group--compete", start: "top center", markers: false},
                opacity: 0,
                // ease: "bounce.out", //"elastic.out(1,0.5)",
                ease: "power4.out", // ease: CustomEase.create("custom", "M0,0 C0,0 0.046,0.09 0.09,0.285 0.119,0.415 0.208,0.72 0.223,0.761 0.246,0.824 0.281,1.014 0.347,1.014 0.424,1.014 0.42,0.937 0.533,0.937 0.634,0.937 0.637,0.98 0.7,1 0.762,1.019 0.79,1.015 0.841,1.016 0.898,1.016 0.927,1 0.981,1 1.022,1 1,1 1,1 "),
                duration: 0.5,
                delay: 2
            });
            gsap.fromTo(".features__bg-splat-ice", {
                scrollTrigger: {trigger:  ".features__group--compete", start: "top center", markers: false},
                scale: 0.5,
                // ease: "bounce.out",
                ease: "power4.out", // ease: CustomEase.create("custom", "M0,0 C0,0 0.046,0.09 0.09,0.285 0.119,0.415 0.208,0.72 0.223,0.761 0.246,0.824 0.281,1.014 0.347,1.014 0.424,1.014 0.42,0.937 0.533,0.937 0.634,0.937 0.637,0.98 0.7,1 0.762,1.019 0.79,1.015 0.841,1.016 0.898,1.016 0.927,1 0.981,1 1.022,1 1,1 1,1 "),
                duration: 0.3,
                delay: 2
                },
                {
                scrollTrigger: {trigger:  ".features__group--compete", start: "top center", markers: false},
                scale: 1.0,
                ease: "power4.out", // ease: CustomEase.create("custom", "M0,0 C0,0 0.046,0.09 0.09,0.285 0.119,0.415 0.208,0.72 0.223,0.761 0.246,0.824 0.281,1.014 0.347,1.014 0.424,1.014 0.42,0.937 0.533,0.937 0.634,0.937 0.637,0.98 0.7,1 0.762,1.019 0.79,1.015 0.841,1.016 0.898,1.016 0.927,1 0.981,1 1.022,1 1,1 1,1 "),
                duration: 0.3,
                delay: 2
                }
            );
            
        }, featuresArea)
        return ()=>{featuresCtx.revert();}
    }, []);

    return (
        <section className="features" ref={featuresArea}>
            {/* <h2 className="features__title">Welcome to Cubric.</h2> */}
            {/* <h3 className="features__subtitle">Imagine a world of limitless possibilities.</h3> */}
            <h2 className="features__title features__title--mobile">Imagine a world of limitless possibilities.</h2>
            <h2 className="features__title features__title--non-mobile">Imagine a world of limitless&nbsp;possibilities.</h2>
            <div className="features__group features__group--create">
                <img className="features__image" src={ImageCreate} alt="Create with Cubric screenshot" loading="lazy" />
                <div className="features__copy-box">
                    <div className="features__bg features__bg-flamingo">
                        <img className="features__bg-flamingo-img" src={FlamingoBG} loading="lazy" alt="" />
                    </div>
                    <h3 className="features__copy-title">Create</h3>
                    <div className="features__copy-body">Build the digital paradise of your dreams with amazing blueprints and materials that inspire and attract the world's best creators.</div>
                </div>
            </div>
            <div className="features__group features__group--connect">
                <div className="features__copy-box  features__copy-box--connect">
                    <video className="features__bg features__bg-star"
                        poster={StarImg}
                        muted
                        loop
                        playsInline
                        autoPlay>
                            <source src={StarAnim} type="video/mp4" />
                    </video>
                    <video className="features__bg features__bg-rainbow"
                        poster={RainbowImg}
                        muted
                        loop
                        playsInline
                        autoPlay>
                            <source src={RainbowAnim} type="video/mp4" />
                    </video>
                    <h3 className="features__copy-title">Connect</h3>
                    <div className="features__copy-body">Join old and new friends in an immersive party island designed to create memories and magic.</div>
                </div>
                <img className="features__image" src={ImageConnect} alt="Create with Cubric screenshot" loading="lazy" />
            </div>
            <div className="features__group features__group--compete">
                <img className="features__image" src={ImageCompete} alt="Create with Cubric screenshot" loading="lazy" />
                <div className="features__copy-box">
                    <div className="features__bg-splat-ice">
                        <img className="features__bg-splat-ice-img" src={SplatterIcecream} alt="" loading="lazy" />
                    </div>
                    <div className="features__bg-splat-burger">
                        <img className="features__bg-splat-burger-img" src={SplatterBurger} alt="" loading="lazy" />
                    </div>
                    <h3 className="features__copy-title">Compete</h3>
                    <div className="features__copy-body">Calling all gamers! Choose between an ever-changing series of challenges that scratch that competitive itch.</div>
                </div>
            </div>
        </section>

    )

}

export default Features;